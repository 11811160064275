<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-tennis-ball" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="trip-title">{{trip.title}}</div>
            <div class="meta"><i class="far fa-fw fa-calendar-alt mr-2"></i>{{trip.start_on | moment('MMM Do, YYYY')}} - {{trip.end_on | moment('MMM Do, YYYY')}}</div>
            <div v-if="trip.banner" class="mt-1"><b-tag type="is-primary">{{trip.banner}}</b-tag> </div>
        </template>
        <template v-slot:status>
            <div class="stats">
                <span class="stat" v-if="trip.active === 0"><i class="far fa-ban" style="color:red"></i></span>
                <span class="stat" v-if="trip.registration_count"><i class="far fa-suitcase"></i>{{trip.registration_count}}</span>
                <span class="stat" v-if="trip.wait_list_count"><i class="far fa-clock"></i>{{trip.wait_list_count}}</span>
                <span class="stat" v-if="trip.participant_count"><i class="far fa-users"></i>{{trip.participant_count}}</span>
                <span class="stat" v-if="tasks && trip.tasks && trip.tasks.total !== 0"><b-tag type="is-warning" class="stat" ><i class="fas fa-tasks mr-1"></i>{{trip.tasks.total}}</b-tag></span>
            </div>
        </template>
        <template v-slot:details>
            <div class="user-details">
                <toolbar class="toolbar">
                    <b-tooltip label="Details">
                        <button class="icon-only" @click.stop="$openCard('trip', {tripId: trip.id}, card)"><i class="far fa-tennis-ball"></i></button>
                    </b-tooltip>
                    <b-tooltip label="Registrations">
                        <button class="icon-only" @click.stop="$openCard('registrations', {tripId: trip.id}, card)"><i class="far fa-suitcase-rolling"></i></button>
                    </b-tooltip>
                    <b-tooltip label="Trip Details">
                        <button class="icon-only" @click.stop="$openCard('days', {tripId: trip.id}, card)"><i class="far fa-calendar-alt"></i></button>
                    </b-tooltip>
<!--                    <button class="icon-only" @click.stop=""><i class="far fa-comments-alt"></i></button>-->
                    <b-tooltip label="Edit Trip">
                        <button class="icon-only" @click.stop="$openCard('edit-trip', {tripId: trip.id}, card)"><i class="far fa-pencil"></i></button>
                    </b-tooltip>
                </toolbar>

                <div class="data mt-1">
                    <data-row mini title="ID">{{trip.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'TripListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            trip: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            tasks: {
                type: Boolean,
                default: false
            }

        },
        data: function () {
            return {
                details: false
            }
        },
        methods: {
            login: function () {
                this.$emit('login');
            },
            openCard: function (card, props) {
                this.$openCard(card, props, this.card);
            },
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .trip-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;
        .user-details {
            margin-top: 0.5rem;
        }
    }
</style>
