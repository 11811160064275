<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-layer-plus" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="trip-title">{{addon.title}}</div>
            <div class="meta"><i class="far fa-fw fa-layer-group mr-2"></i>{{addon.type | capitalize}}</div>
        </template>
        <template v-slot:status>
            <div class="stats">
                <span class="stat" v-if="addon.participants.length"><i class="far fa-street-view"></i>{{addon.participants.length}}</span>

                <b-tag type="is-warning" class="stat ml-2" v-if="addon.task_complete === 0"><i class="fas fa-tasks"></i></b-tag>
            </div>
        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <toolbar class="toolbar">
                    <button class="icon-only" @click.stop=""><i class="far fa-pencil"></i></button>
                </toolbar>

                <div class="data mt-1">
                    <data-row mini title="Single">{{addon.single_cost | currency}}</data-row>
                    <data-row mini title="Double">{{addon.double_cost | currency}}</data-row>
                    <data-row mini title="Created">{{addon.created_at | moment('MM/DD/YYYY')}}</data-row>
                    <data-row mini title="ID">{{addon.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'TripAddOnListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            addon: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .trip-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }

    .t-badge {
        background-color: $t-primary;
        border-radius: 4px;
        color: white;
        padding: 2px 7px 0;
        font-size: .7rem;
    }
</style>
