<template>
    <card-list-item chevron @click="$emit('click');" :class="{'active': active}">
        <template v-slot:icon>
            <i class="fa-fw" :class="[icon, {'fas': active, 'far': !active}]"></i>
        </template>
        <template v-slot:content>
            <div class="t-title">{{title}}</div>
            <div class="t-subtitle" v-if="subtitle">{{subtitle}}</div>
        </template>
        <template v-slot:badge v-if="badge">
            <div class="t-badge">{{badge}}</div>
        </template>
    </card-list-item>
</template>

<script>
    import CardListItem from "./CardListItem";
    export default {
        name: 'NavigationListItem',
        components: {CardListItem},
        props: {
            title: String,
            subtitle: String,
            icon: String,
            badge: [String, Number],
            active: Boolean
        },
        data: function () {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 18);
        padding: 0.7rem 0 0.45rem;


        .t-title {
            font-size: .9rem;
            font-weight: 700;
        }

        .t-subtitle {
            margin: 0.3rem 0;
            font-size: .83rem;
            font-weight: normal;
            line-height: 1rem;
        }
    }

    .t-badge {
        background-color: $t-primary;
        border-radius: 4px;
        color: white;
        padding: 2px 7px 0;
        font-size: .7rem;
    }
</style>
