<template>
    <div>
        <card-header title="Questions" :subheader="trip.title" icon="fa-clipboard-list-check"/>
        <card-toolbar>
            <button @click="$openCard('new-question', {tripId: props.tripId}, card)"><i class="fas fa-plus mr-2"/>New Question</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <subheader-list-item title="Questions" icon="fas fa-clipboard-list-check"/>
                <draggable v-model="questions" handle=".drag-handle" @end="completeDrag">
                    <transition-group>
                        <question-list-item
                            :question="q"
                            :card="card"
                            :active="q.active"
                            v-for="q in questions"
                            :key="q.id"
                            @click="$openCard('question', {questionId: q.id}, card)"
                            class="t-card-list-item"
                            :badge="q.responses.length"
                        />
                    </transition-group>
                </draggable>
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import draggable from 'vuedraggable';

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DayListItem from "../../components/DayListItem";
    import TripAddOnListItem from "../../components/TripAddOnListItem";
    import QuestionListItem from "@/components/QuestionListItem";

    export default {
        name: "RegistrationQuestions",
        props: ['card', 'child', 'props'],
        components: {draggable, QuestionListItem, TripAddOnListItem, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                questions: [],
                trip: {}
            }
        },
        methods: {
            loadQuestions: function(force) {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId + '/questions/0/30', {force}).then(response => {
                    for (let i = 0; i < response.data.questions.length; i++) {
                        response.data.questions[i].active = false;
                    }
                    this.questions = response.data.questions;
                    this.setActive();
                    this.$emit('loading', false);
                });
            },
            loadTrip: function() {
                this.$emit('loading', true);
                http.get('/api/trips/' + this.props.tripId).then(response => {
                    this.trip = response.data;
                });
            },
            setActive: function () {
                let check = (this.child && this.child.definition.component === 'question') ? parseInt(this.child.props.questionId) : null;
                this.questions.map(q => q.active = (q.id === check));
            },
            completeDrag: function() {
                http.post('/api/questions/order', this.questions.map(p => p.id)).then(response => {

                });
            },
            reload: function() {
                console.log('reload questions');
                this.loadQuestions(true);
            }
        },
        watch: {
            'props.tripId': function() {
                this.loadQuestions();
            },
            'child': function () {
                this.setActive();
            }
        },
        mounted() {
            this.loadQuestions();
            this.loadTrip();
        }
    };
</script>

<style lang="scss" scoped>

</style>
