<template>
    <li></li>
</template>

<script>
    export default {
        name: 'SeparatorListItem'
    }
</script>

<style lang="scss" scoped>
    li {
        background-color: darken($t-primary, 10) !important;
        border-bottom: 1px solid darken($t-primary, 10) !important;
        height: 5px;
        cursor: default !important;
    }
</style>
