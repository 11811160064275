import { render, staticRenderFns } from "./NavigationListItem.vue?vue&type=template&id=00565f4c&scoped=true&"
import script from "./NavigationListItem.vue?vue&type=script&lang=js&"
export * from "./NavigationListItem.vue?vue&type=script&lang=js&"
import style0 from "./NavigationListItem.vue?vue&type=style&index=0&id=00565f4c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00565f4c",
  null
  
)

export default component.exports