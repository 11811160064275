<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-clipboard-list-check" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="trip-title">{{question.question}}</div>
        </template>
        <template v-slot:status>
            <i class="fas fa-sort drag-handle" v-if="!noSort"></i>
        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <toolbar class="toolbar">
                    <button class="icon-only" @click.stop="$openCard('question-responses', {questionId: question.id}, card)"><i class="far fa-poll-people"></i></button>
                    <button class="icon-only" @click.stop="$openCard('edit-question', {questionId: question.id}, card)"><i class="far fa-pencil"></i></button>
                </toolbar>
                
                <div class="data mt-1">
                    <data-row mini title="Responses" v-if="question.responses">{{question.responses.length}}</data-row>
                    <data-row mini title="ID">{{question.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'QuestionListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            question: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            noSort: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .trip-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }

    .drag-handle {
        cursor: ns-resize;
    }
</style>
