<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}">
        <template v-slot:icon><i class="fa-fw fa-calendar-day" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="trip-title">{{day.title}}</div>
            <div class="meta" v-if="day.type === 'pre'"><i class="far fa-fw fa-sort-circle-up mr-2"></i>Pre-Extension<span v-if="day.number">, Day {{day.number}}</span></div>
            <div class="meta" v-if="day.type === 'trip'"><i class="far fa-fw fa-tennis-ball mr-2"></i>Main Trip<span v-if="day.number">, Day {{day.number}}</span></div>
            <div class="meta" v-if="day.type === 'post'"><i class="far fa-fw fa-sort-circle-down mr-2"></i>Post-Extension<span v-if="day.number">, Day {{day.number}}</span></div>
            <div class="meta" v-if="day.type === 'hidden'"><i class="far fa-fw fa-minus-circle mr-2"></i>Private<span v-if="day.number">, Day {{day.number}}</span></div>

            <div class="meta" v-if="day.day_on"><i class="far fa-fw fa-calendar-day mr-2"></i>{{day.day_on | moment('dddd, MMMM Do, YYYY')}}</div>
        </template>
        <template v-slot:status>
            <div class="stats">
                <span class="stat" v-if="day.event_count"><i class="far fa-users"></i>{{day.event_count}}</span>
                <span class="stat" v-if="day.event_task_count"><b-tag type="is-warning" class="stat" ><i class="fas fa-tasks mr-1"></i>{{day.event_task_count}}</b-tag></span>
            </div>
        </template>
        <template v-slot:details>
            <div class="details-rollout">
                <toolbar class="toolbar">
                    <button class="icon-only" @click.stop=""><i class="far fa-plus"></i></button>
                    <button class="icon-only" @click.stop=""><i class="far fa-pencil"></i></button>
                </toolbar>

                <div class="data mt-1">
                    <data-row mini title="ID">{{day.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";

    export default {
        name: 'DayListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            day: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            }
        }
    }
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .trip-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .details-rollout {
            margin-top: 0.5rem;
        }
    }
</style>
